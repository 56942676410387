import React, { Component } from 'react'
import Layout from '../layout'
import { getCategoryDisplayName, getTranslateFunction, scrollToSlug } from '../../utilities'
import { Link } from 'gatsby'
import CaseStudiesSideMenu from '../case-studies/caseStudiesSideMenu'
import { getSlug } from '../../getSlug'
import CaseStudiesSideMenuGhost from '../case-studies/caseStudiesSideMenuGhost'
import VisibilitySensor from 'react-visibility-sensor'
import Img from 'gatsby-image'
import Reveal from '../reveal'
/* import { TranslatedLink } from '../TranslatedLink' */
import MultiLayerTilt from '../MultiLayerTilt'
import PageTitle from '../pageTitle'
import ContaktUs from '../contactUs/ContactUs'
import LinkBtn from '../linkBtn/LinkBtn'
import './Pages.scss';


class FeaturedReferencesTemplate extends Component {
  constructor(props) {
    super(props)
    this.onEnterViewport = this.onEnterViewport.bind(this)
    this.onExitViewport = this.onExitViewport.bind(this)
    this.visibleElementChangeHandler = this.visibleElementChangeHandler.bind(this)
    this.visibleElementsStack = []

    this.state = {
      visibleElement: null,
    }
  }

  onEnterViewport(id) {
    this.setState({
      visibleElement: id,
    })
  }

  onExitViewport(id) {
    // if (this.state.visibleElement === null) {
    //   this.setState({
    //     visibleElement: id,
    //   })
    // } else {
    //   this.setState({
    //     visibleElement: null,
    //   })
    // }
  }

  visibleElementChangeHandler(category) {
    this.setState({
      visibleElement: category,
      visibleElementsStack: [],
    })
  }

  componentDidMount() {
    scrollToSlug()
    CaseStudiesSideMenuGhost.setCurrentPage('featured')
  }

  render() {

    const { data, lang } = this.props
    const description = data.references.childMarkdownRemark.frontmatter.description
    const caseStudies = data.references.childMarkdownRemark.frontmatter.case_studies

    let categoriesList = caseStudies.map((caseStudy) => caseStudy.title)

    let ScrollTriggerEl = VisibilitySensor
    if (typeof window === 'undefined') {
      ScrollTriggerEl = (props) => (<div {...props}>{props.children}</div>)
    }

    CaseStudiesSideMenuGhost.setActivePage(lang, 'featured', categoriesList, true, this.state.visibleElement, this.visibleElementChangeHandler)

    const t = getTranslateFunction(this.props.lang)

    return (
      <Layout lang={lang} className={'mobile-menu-offset footer-hide  our-work'}>
        <section className="references project-refs margin-top featured">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-md-3" id="left">
              <CaseStudiesSideMenu
                categoriesList={categoriesList}
                visibleElementChangeHandler={this.visibleElementChangeHandler}
                visibleElement={this.state.visibleElement}
                rawCategoryNames={true}
                lang={lang}
                isGhost={false} />
            </div>
            <div className="col-lg-9 pr-md-0">
              <div className="row subpage-title">
                <div className="col-md-10">
                  <PageTitle title={t('References.FeaturedTitle')} description={description} url="https://test.narative.sk/nase-prace" />
                  <p>{description}</p>
                  {/*      <div className="col-auto">
                    <LinkBtn>
                      <TranslatedLink to={'/en/contact'}>{t('References.ContactUs')}</TranslatedLink>
                    </LinkBtn>
                  </div> */}
                </div>
              </div>

              <div className={'references-group-featured cs'}>
                {caseStudies.map((caseStudy, i) => {
                  const bgColor = caseStudy.background_color
                  const background = `linear-gradient(105deg, transparent 0%, transparent 60%, ${bgColor} 60%, ${bgColor} 100%)`
                  return (
                    <Reveal key={i} id={`${getSlug(caseStudy.title)}`} placeholderHeight={780 + 130} otherClassName={`references-block ` + (i === 0 ? 'first' : '')}>
                      <ScrollTriggerEl
                        minTopValue={500}
                        onChange={(val) => {
                          if (val) {
                            this.visibleElementsStack = [...this.visibleElementsStack, caseStudy.title]
                          } else {
                            this.visibleElementsStack = this.visibleElementsStack.filter(e => e !== caseStudy.title)
                          }
                          this.onEnterViewport(this.visibleElementsStack[this.visibleElementsStack.length - 1])
                        }}
                        partialVisibility={true}
                        key={caseStudy.title}
                      >
                        <>
                          <div className={'row  mt-5 mt-md-0 align-items-center'}>
                            <div className="col-md-6 left">
                              <Link to={caseStudy.case_study}>
                                <h3>{caseStudy.title}</h3>
                              </Link>
                              <h4>{getCategoryDisplayName(caseStudy.category, lang)}</h4>
                              <p>{caseStudy.description}</p>
                              <LinkBtn className={'d-none d-md-flex'}>
                                <Link to={caseStudy.case_study}>{t('References.OpenCaseStudyBtn')}</Link>
                              </LinkBtn>
                            </div>
                            <div className="col-md-6 right">
                              <Link to={caseStudy.case_study}>
                                <MultiLayerTilt layers={caseStudy.thumbnail_layers.map((img) => (
                                  <Img fluid={img.thumbnail.childImageSharp.fluid} alt={caseStudy.title} />
                                ))} />
                              </Link>
                              <LinkBtn className={'d-flex d-md-none justify-content-center '}>
                                <Link to={caseStudy.case_study}>{t('References.OpenCaseStudyBtn')}</Link>
                              </LinkBtn>
                            </div>
                          </div>
                          <div className={'background-element'} style={{ background: background }} />
                        </>
                      </ScrollTriggerEl>
                    </Reveal>
                  )
                })}

              </div>
            </div>
          </div>
        </section>

        <Reveal>
          <ContaktUs
            LeftSideSubTitle={t('References.MoreProjects.Webpages')}
            LeftSideLink="/en/our-works/websites"
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>
      </Layout>)
  }
}

export default FeaturedReferencesTemplate
